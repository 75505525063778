<template>
  <div class="container">
    <div
      class="module header"
      :style="{
        backgroundColor: `transparent`,
        backgroundImage: `radial-gradient(transparent 1px, rgba(255,255,255, ${
          scrollHeight / 100 + 0.2
        }) 1px)`,
        boxShadow: `0 0 10px rgba(204,204,204, ${scrollHeight / 100})`
      }"
    >
      <header-module></header-module>
    </div>
    <!-- <el-affix>
      </el-affix> -->
    <div class="module banner" id="home">
      <banner-module :data="bannerData"></banner-module>
    </div>
    <div class="module" id="about">
      <about-module></about-module>
    </div>
    <div class="module" id="platform">
      <platform-module></platform-module>
    </div>
    <div class="module" id="cooperative">
      <cooperative-module></cooperative-module>
    </div>
    <div class="module" id="contact">
      <contact-module></contact-module>
    </div>
    <div class="footer">
      <div class="footer-inner">
        <el-space :size="30">
          <div>
            <el-link
              type="primary"
              @click="linkTo('https://beian.miit.gov.cn/')"
              > {{recordNumber || 'Copyright 2024 SUMOU All Rights Reserved 蜀ICP备2023042080号-1'}}</el-link
            >
          </div>
          <div>
            <el-link
              type="primary"
              @click="linkTo('https://beian.mps.gov.cn/#/query/webSearch?recordcode=51012202001705')"
              >{{publicSecurityFiling || '川公网安备51012202001705'}}</el-link
            >
          </div>
        </el-space>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderModule from '@/components/pc/Header.vue'
import BannerModule from '@/components/pc/Banner.vue'
import AboutModule from '@/components/pc/About.vue'
import CooperativeModule from '@/components/pc/Cooperative.vue'
import PlatformModule from '@/components/pc/Platform'
import ContactModule from '@/components/pc/Contact'

import 'animate.css'
import Wow from 'wow.js'
import { mapState } from 'vuex'
export default {
  name: 'home-page',
    computed: {
      ...mapState(['publicSecurityFiling', 'recordNumber'])
    },
  components: {
    HeaderModule,
    BannerModule,
    AboutModule,
    CooperativeModule,
    PlatformModule,
    ContactModule
  },
  data() {
    return {
      isMobile: true,
      scrollHeight: 0
    }
  },
  unmounted() {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    linkTo(url) {
      window.open(url, '_blank')
    },
    handleScroll() {
      this.scrollHeight = window.scrollY / 3
      // 处理滚动事件
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    const wow = new Wow({
      // 对所有拥有该class的元素挂载动画
      boxClass: 'scroll-item',
      // 需要挂载的动画元素
      animateClass: 'animate__animated',
      // 偏移值（即偏移一定距离后再执行动画，推荐设置！）
      offset: 100,
      // 移动设备是否生效
      mobile: true,
      // 是否支持异步
      live: true
    })
    wow.init()
  }
}
</script>

<style scoped lang="less">
.container {
  overflow: hidden;
  .module {
    background: #fff;
    &.header {
      position: fixed;
      // background-image: radial-gradient(transparent 1px, #fff 1px);
      background-size: 4px 4px;
      backdrop-filter: saturate(50%) blur(4px);
      z-index: 10;
      width: 100%;
      top: 0;
      height: 81px;
      box-shadow: 0 0 10px rgb(204, 204, 204);
    }
  }
  .footer {
    height: 60px;
    display: flex;
    justify-content: center;
    background: #fff;
    .footer-inner {
      width: 1000px;
      height: 100%;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
