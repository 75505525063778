// store.js
import logo from '@/assets/logo.png'
import shouqian from '@/assets/image/qrcode/shouqian.png'
import shouhou from '@/assets/image/qrcode/shouhou.png'
import gongzhonghao from '@/assets/image/qrcode/gongzhonghao.png'
import { createStore } from 'vuex';
import axios from 'axios';
export default createStore({
    state() {
        return {
            baseUrl: 'https://cpsserver.sumou.fun',
            titlelog: '',  //logo 图片
            webname: '',   // 网站名字
            webphone: '',  // 联系人
            preSales: '', //售前微信图片
            afterSales: '', //售后微信
            account: '', //售后微信
            recordNumber: '',  //工信部备案号
            publicSecurityFiling: '',  //公安备案号
        };
    },
    mutations: {
        setInfos(state, data) {
            state.titlelog = data?.titlelogUrl || logo
            state.webname = data?.webname || ''
            state.webphone = data?.webphone || '18884860086'
            state.preSales = data.preSalesUrl || shouqian
            state.afterSales = data.afterSalesUrl || shouhou
            state.account = data.accountUrl || gongzhonghao
            state.recordNumber = data.recordNumber
            state.publicSecurityFiling = data.publicSecurityFiling
        }
    },
    actions: {
        async getCustomInfo({ state, commit }) {
            const location = window.location
            axios.get(`${state.baseUrl}/setting/getserversetting?domain=${location.host}`)
                .then(async (res) => {
                    if (res.data) {
                        const { afterSales, preSales, account, publicSecurityFiling, recordNumber, titlelog, webname, webphone } = res.data;
                        const afterSalesUrl = afterSales?`https://file.sumou.fun/${afterSales}`: ''
                        const preSalesUrl = preSales? `https://file.sumou.fun/${preSales}` :''
                        const accountUrl = account ? `https://file.sumou.fun/${account}` : ''
                        const titlelogUrl = `https://file.sumou.fun/${titlelog}`
                        document.title = webname
                        commit('setInfos', {
                            afterSalesUrl, preSalesUrl,accountUrl, publicSecurityFiling, recordNumber, titlelogUrl, webname, webphone
                        })
                    } else {
                        commit('setInfos', {})
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },
    getters: {

    }
});

