<template>
  <div class="about">
    <div class="about-title">
      <div class="title-text">关于我们</div>
      <div class="title-subtext">
        <p>
          Cps全场景分佣服务平台的推出，打破了传统单一平台抽佣的局限，实现了跨平台、跨服务商的无缝对接。无论平台服务商在哪个平台运
        </p>
        <p>
          营，都可以通过系统管理所有平台的抽佣事务，提高了运营效率和资金归集结算的线上闭环运营链路。
        </p>
      </div>
    </div>
    <div class="about-content">
      <div class="about-item" v-for="(item, index) in data" :key="index">
        <div class="about-item__inner">
          <div
            class="image scroll-item animate__bounceInLeft"
            data-wow-duration="2s"
            :style="{ height: item.height }"
          >
            <img :src="item.image" alt="" />
          </div>
          <div
            class="content scroll-item animate__bounceInRight"
            data-wow-duration="2s"
          >
            <div class="title">{{ item.title }}</div>
            <div class="description" style="animation-delay: 0.2s">
              {{ item.desc }}
            </div>
            <div class="menus" style="animation-delay: 0.4s">
              <el-space :size="50">
                <div
                  class="menu-item"
                  v-for="(menu, index) in item.menus"
                  :key="index"
                >
                  <div class="menu-icon">
                    <img :src="menu.icon" alt="" />
                  </div>
                  <div class="menu-label">{{ menu.label }}</div>
                </div>
              </el-space>
            </div>
            <div class="more" style="animation-delay: 0.6s">
              <el-anchor :offset="120" direction="horizontal">
                <el-anchor-link href="#contact" :marker="false"> 
                    <el-button size="large" round type="primary">了解更多</el-button>
                </el-anchor-link>
              </el-anchor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kuapingtai from "@/assets/image/about/kuapingtai.png";
import zidongfenzhang from "@/assets/image/about/zidongfenzhang.png";
import zijinanquan from "@/assets/image/about/zijinanquan.png";
import shujufenxi from "@/assets/image/about/shujufenxi.png";
import gexinghua from "@/assets/image/about/gexinghua.png";
import icon1_1 from "@/assets/image/about/icon1-1.png";
import icon1_2 from "@/assets/image/about/icon1-2.png";
import icon1_3 from "@/assets/image/about/icon1-3.png";
import icon2_1 from "@/assets/image/about/icon2-1.png";
import icon2_2 from "@/assets/image/about/icon2-2.png";
import icon3_1 from "@/assets/image/about/icon3-1.png";
import icon3_2 from "@/assets/image/about/icon3-2.png";
import icon3_3 from "@/assets/image/about/icon3-3.png";
import icon4_1 from "@/assets/image/about/icon4-1.png";
import icon4_2 from "@/assets/image/about/icon4-2.png";
import icon4_3 from "@/assets/image/about/icon4-3.png";
import icon5_1 from "@/assets/image/about/icon5-1.png";
import icon5_2 from "@/assets/image/about/icon5-2.png";
export default {
  name: "about-component",
  data() {
    return {
      data: [
        {
          title: "跨平台对接",
          height: "550px",
          image: kuapingtai,
          desc: "全平台抽佣系统能够接入多个线上平台，如电商平台、社交媒体、本地生活服务平台等，实现资源的有效整合和共享。商家无需在多个平台间来回切换，即可实现一站式管理。",
          menus: [
            {
              icon: icon1_1,
              label: "资源整合",
            },
            {
              icon: icon1_2,
              label: "一站式管理",
            },
            {
              icon: icon1_3,
              label: "降本增效",
            },
          ],
        },
        {
          title: "自动化分账",
          height: "582px",
          image: zidongfenzhang,
          desc: "系统通过与银行和支付机构的合作，实现资金的自动化分账。公域平台资金自动进入商家的监管结算账户， 银行按照系统后台设定的指令回调，按比例自动执行分账操作， 确保流程的准确性和高效性。",
          menus: [
            {
              icon: icon2_1,
              label: "灵活比例配置",
            },
            {
              icon: icon2_2,
              label: "连通公域",
            },
          ],
        },
        {
          title: "资金安全保障",
          // height: "571px",
          image: zijinanquan,
          desc: "平台采用先进的资金监管和风险控制机制， 确保商家资金的安全。线上平台结算后， 资金会实时自动化分账至服务商和商家的收款账户， 避免资金在平台沉淀，降低资金风险。",
          menus: [
            {
              icon: icon3_1,
              label: "资金监管",
            },
            {
              icon: icon3_2,
              label: "风险管控",
            },
            {
              icon: icon3_3,
              label: "实时分账",
            },
          ],
        },
        {
          title: "数据分析功能",
          height: "582px",
          image: shujufenxi,
          desc: "系统提供强大的数据分析功能，可实时查看销售数据、 用户行为等信息，为经营决策提供有力支持。通过数据驱动 ，服务商可以更精准地把握市场动态，制定有效的营销策略。",
          menus: [
            {
              icon: icon4_1,
              label: "洞悉客户",
            },
            {
              icon: icon4_2,
              label: "赋能营销",
            },
            {
              icon: icon4_3,
              label: "决策支持",
            },
          ],
        },
        {
          title: "个性化设置",
          height: "704px",
          image: gexinghua,
          desc: "系统支持根据合作伙伴的需求进行个性化设置，如调整抽佣比例、设置分账规则等，自定义设置登录页面，域名等。",
          menus: [
            {
              icon: icon5_1,
              label: "千人千面",
            },
            {
              icon: icon5_2,
              label: "灵活定制",
            },
          ],
        },
        {
          title: "私域本地通",
          height: "704px",
          image: zijinanquan,
          desc: "以微信小程序为载体，以流量公转私，实现本地生活服务商商家流量私有化。使流量更精准，变现更容易。使流量变为忠实客户。",
          menus: [
            {
              icon: icon3_1,
              label: "私有化",
            },
            {
              icon: icon3_2,
              label: "智能化",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
:deep(.el-button) {
  width: 180px;
}
:deep(.el-anchor) {
    background: transparent;
    .el-anchor__marker {
        opacity: 0 !important;
    }
}
.about {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .about-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-text {
      font-weight: 700;
      font-size: 34px;
    }
    .title-subtext {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;
      p {
        margin: 0;
        padding: 0;
        line-height: 28px;
      }
    }
  }
  .about-content {
    width: 100vw;
    .about-item {
      display: flex;
      justify-content: center;
      &:nth-child(odd) {
        background: #f5f7fc;
        .about-item__inner {
          flex-direction: row-reverse;
        }
      }
    }
    .about-item__inner {
      width: 1245px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 50px;
      .image {
        overflow: hidden;
        width: 620px;
        min-width: 620px;
        img {
          width: 100%;
        }
      }
      .content {
        width: 578px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 26px;
          font-weight: 600;
          line-height: 45px;
          padding: 30px 0 30px;
        }
        .description {
          font-size: 16px;
          line-height: 28px;
        }
        .menus {
          display: flex;
          justify-content: space-between;
          padding-top: 30px;
          .menu-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 4px;
            padding: 4px 10px;
            .menu-icon {
              height: 68px;
              img {
                height: 100%;
              }
            }
            .menu-label {
              line-height: 48px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>